:root {
  --p: 1rem;
  --accent: rgb(185, 190, 197);
  font-family: sans-serif;
}

html,
body {
  position: fixed;
  overflow: hidden;
}

body {
  overscroll-behavior: none;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

#root {
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  width: 100vw;
  height: 100vh;
}

.App {
  min-height: 100%;
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

button {
  font-family: inherit;
  font-size: inherit;
  appearance: none;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

button:focus {
  outline: none;
}

ul,
ol {
  margin: 0;
  padding: 0;
}
